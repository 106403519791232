import { qs, qsa } from "./utils.js"

function validateStep(step) {
  console.debug({ step })
  if ((step = step.closest(".form__step"))) {
    step.classList.add("form__step--validated")
    for (const el of step.querySelectorAll("input, select, textarea")) {
      if (!el.checkValidity()) {
        el.reportValidity()
        return false
      }
    }
    return true
  }
  return false
}

export function initForms() {
  const cancel = qs("[data-cancel-form]")
  for (const el of qsa("[data-stepped-form]")) {
    el.addEventListener("submit", window.recaptchaFormSubmit)
    el.addEventListener("click", async (e) => {
      if (e.target.matches("button[type='button']") && validateStep(e.target)) {
        e.preventDefault() // No default, really.
        window.grecaptcha.ready(async () => {
          const body = new FormData(el)
          body.append("_continue", "yes")
          const response = await fetch("", {
            credentials: "include",
            method: "POST",
            body,
          })

          const parser = new DOMParser()
          const doc = parser.parseFromString(await response.text(), "text/html")

          const url = response.headers.get("x-submission-url")
          if (url) {
            history.replaceState(null, "", url)
          }

          let stepIndex = 0
          for (const step of qsa(".form__details", el)) {
            if (step.open) break
            ++stepIndex
          }

          const newEl = doc.querySelector("[data-stepped-form]")
          if (newEl) {
            el.innerHTML = newEl.innerHTML
          }

          let newStepIndex = 0
          for (const step of qsa(".form__details", el)) {
            if (qs(".is-invalid", step) || newStepIndex > stepIndex) {
              step.open = true
              break
            }
            if (newStepIndex <= stepIndex) {
              step.open = false
            }
            ++newStepIndex
          }
        })
      }
    })
  }

  if (cancel) {
    cancel.addEventListener("click", async (e) => {
      e.preventDefault()
      const response = await fetch("", {
        credentials: "include",
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
        method: "DELETE",
      })
      if (response.ok) window.location.href = "../../"
    })
  }
}

function getCookie(cookieName) {
  const cookies = document.cookie ? document.cookie.split("; ") : []
  const prefix = `${cookieName}=`
  for (const cookie of cookies) {
    if (cookie.startsWith(prefix))
      return decodeURIComponent(cookie.substring(prefix.length))
  }
}
